import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UserInfoContext = createContext();

const UserProvider = ({ children }) => {
	const [userInfo, setUserInfo] = useState();
	const [accessiblePages, setAccessiblePages] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("userInformation")) {
			setUserInfo(JSON.parse(localStorage.getItem("userInformation")));
		}
	}, []);

	const getUserInfo = async (id, token = "", action = "") => {
		// alert(action);
		// if (token === '') {
		// if (action !== 'login') {
		//     id = localStorage.getItem("user-authorization-token")?.userid;
		//     token = localStorage.getItem("user-authorization-token")?.token;
		// }
		// if (id && token) {
		//     // navigate("/");
		// }
		// return null;
		// }
		// }

		setIsLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_LOGIN_API_DOMAIN}/api/userinfomation.php`,
				{ userid: id, token: token },
				{
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Access-Control-Allow-Methods": "POST",
						"Content-Type": "multipart/form-data"
					}
				}
			)
			.then((result) => {
				if (result?.data?.status) {
					const data = {
						...result?.data,
						userId: id
					};
					const parseData = { ...data, casino_information: data?.casino_information?.map((casino) => ({ ...casino, ...(casino?.test_players && { test_players: JSON.parse(casino?.test_players) }) })) };
					setAccessiblePages(parseData?.accessible_pages);
					setUserInfo(parseData);
					localStorage.setItem("userInformation", JSON.stringify(parseData));
					if (localStorage?.getItem("currentCasino") === null) {
						localStorage.setItem("currentCasino", JSON?.stringify(parseData?.casino_information[0]));
					}
				} else {
					toast.error(result?.data?.error, { theme: "dark" });
					localStorage?.removeItem("user-authorization-token");
					localStorage?.removeItem("currentCasino");
					localStorage?.removeItem("userInformation");
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<UserInfoContext.Provider
			value={{
				getUserInfo,
				userInfo,
				accessiblePages,
				isLoading
			}}
		>
			{children}
		</UserInfoContext.Provider>
	);
};

export const UserInfoState = () => {
	return useContext(UserInfoContext);
};

export default UserProvider;
